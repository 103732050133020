header {
  height: auto;
  padding: 0;
  position: fixed;
  width: 100%;
  z-index: 999;
}

header i.icon {
  position: absolute;
  right: 30px;
  top: 32px;
  cursor: pointer;
  z-index: 10;
  display: none;
}

.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}

header .logo h2 {
  font-size: 30px;
  color: #fff;
}

header nav .nav-list {
  list-style: none;
  margin-bottom: 0;
  display: flex;
  justify-content: space-around;
}

header .navbar-light .navbar-nav .nav-link {
  display: block;
  padding: 5px 0.3rem;
  margin: 9px 0.2rem;
  font-size: 1.2rem;
  color: #fff;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.nav-link a {
  color: #7cb62f;
}

a.nav-line {
  text-decoration: none;
  border: 3px solid rgb(23, 33, 80);
  color: rgb(23, 33, 80);
  border-radius: 10px;
  padding: 0.2rem 1rem;
  margin: 0 0.7rem;
}

a.nav-line:hover {
  color: #fff;
  background: rgb(23, 33, 80);
  transition: all 0.3s ease-in-out;
}

header .navbar-light .navbar-nav .lang {
  padding: 0;
  margin: 0 !important;
}

.image-details {
  max-width: 90%;
}

header .navbar-light .navbar-nav .nav-link:hover, header .navbar-light .navbar-nav a:hover {
  color: #7cb62f;
}

.sticky {
  background: rgba(23, 33, 80, 0.7);
  box-shadow: 0 2px 10px 0 #000;
  color: #fff !important;
  transition: all 0.3s ease-in-out;
}

.sticky .navbar-light .navbar-nav .nav-link {
  padding: 14px 0.3rem;
  transition: all 0.4s ease-in-out;
}

.sticky .logo h2 {
  color: #000;
}

@media (max-width: 575px) {
  .navbar-light .navbar-toggler {
    color: #ccc !important;
    border-color: rgba(255, 255, 255, 0.8);
  }
  header {
    background: #00000065;
  }
  header .logo {
    text-align: center;
  }
  header nav .nav-list {
    padding: 0;
    text-align: center;
    display: none;
  }
  header i.icon {
    display: block;
  }
}

/* home */

a {
  text-decoration: none;
  color: var(--primary-color);
}

.showcase {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  color: #fff;
}

.video-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: var(--primary-color) url("/img/1.jpg") no-repeat center center/cover;
}

.content {
  z-index: 10;
}

.btn-our {
  background-color: #7cb62f;
  border-radius: 30px;
  padding: 0.5rem 1rem;
  color: white;
}

.btn-our:hover {
  padding: 0.6rem 1.6rem;
  background: #72c70a;
  border: 1px solid #fff;
  transition: all 0.5s ease-in-out;
  color: white;
}

.btn-our:hover {}

.video-container video {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-container:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(4, 5, 12, 0.6);
}

/* team ============= */

.item .team-info {
  text-align: right;
  color: #fff;
  direction: rtl;
}

.item .team-info img {
  max-height: 280px;
  max-width: 280px;
}

.item .team-info .item-info-title {
  margin: auto;
  margin: 5px 0;
  padding-right: 0.7rem;
}

.item .team-info .item-info-desc {
  display: block;
  margin-bottom: 5px;
  padding-right: 1rem;
}

.item .team-info .en {
  text-align: left;
}

.item .team-info .team-info-social {
  background: #252525 !important;
  position: relative;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  color: #fff;
}

.item .team-info .team-info-social a {
  position: relative;
  margin: auto;
  z-index: 15;
  color: #72c70a;
}

.item .team-info .bg {
  display: block;
  position: absolute;
  width: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: all 0.5s ease-in-out;
}

.item .team-info:hover .bg {
  width: 100%;
  background: #7cb62f;
}

.item .team-info:hover .team-info-social a {
  color: #252525;
  font-size: 1rem;
  cursor: pointer;
}

.owl-nav {
  padding: 0;
  text-align: center;
  font-size: 4rem;
  color: #fff;
}

.owl-dots .owl-dot {
  width: 35px;
  height: 9px;
  background: rgb(23, 33, 80) !important;
  margin: 0 0.2rem;
  border-radius: 15px;
  border: #7cb62f solid 1px !important;
}

.owl-dots .owl-dot:hover {
  background: #7cb62f !important;
  transition: all 0.3s ease-in-out;
}

.owl-dots .active {
  background: #7cb62f !important;
  border: none;
}

.goals {
  clip-path: polygon(0 0, 100% 1%, 100% 94%, 0 100%);
  background-image: url("./img/lap-stat.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: relative;
  color: #fff;
}

.overlay {
  background: rgba(0, 0, 0, 0.8);
}

.nav-tabs .nav-link.active {
  color: #fff;
  background-color: #7cb62f;
  border-color: #dee2e6;
  border-bottom: none;
}

.nav-tabs a {
  text-decoration: none;
  color: #7cb62f;
}

/* Services Section ====================== */

.services .serv {
  background: #fff;
  padding: 10px;
  text-align: center;
  margin-bottom: 20px;
  transition: all 0.3s ease-in-out;
  border: 2px solid #7cb62f;
  border-radius: 80px;
}

.services .serv .icons {
  background: #7cb62f;
  margin: 0.5rem auto;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  color: #fff;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  border: 2px solid #f6f6f6;
}

.services .serv-title {
  font-weight: bold;
  font-size: 25px;
  color: #777;
  margin-bottom: 20px;
  transition: all 0.3s ease-in-out;
}

.non:hover {
  text-decoration: none;
}

.services .serv-desc {
  font-size: 17px;
  color: #888;
  margin-bottom: 20px;
  height: 200px;
  padding: 0.3rem auto;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.services .serv:hover {
  background: #7cb62f;
}

.services .serv:hover h2.icons {
  background: #fff;
  color: #7cb62f;
}

.services .serv:hover .serv-title, .services .serv:hover .serv-desc {
  color: #fff;
}

/* testimonial ============= */

.testimonial {
  clip-path: polygon(0 0, 100% 1%, 100% 94%, 0 100%);
  background-color: #232323;
  padding: 3rem 1.2rem;
}

.testimonial .section-header {
  color: #fff;
}

.testimonial .test-info {
  text-align: center;
  background-color: #141414;
  padding: 20px 10px;
  color: #fff;
}

.testimonial .test-info img {
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid #000;
  margin: auto;
}

.testimonial .test-info .test-info-title {
  font-size: 20px;
  margin-bottom: 10px;
}

.testimonial .test-info .test-info-span {
  color: #999;
  display: block;
  margin-bottom: 10px;
}

.testimonial .test-info i {
  color: #f2b100;
  margin-bottom: 10px;
}

#members .owl-nav {
  display: block;
}

#speakers .owl-dots {
  display: block;
}

.owl-next, .owl-prev {
  margin: 0 2rem;
  color: #fff;
}

.testimonial .test-info .test-info-desc {
  color: #999;
}

/* Important Date===== */

#impo {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 93%);
  background: rgb(13, 110, 148);
  padding: 5rem 1.2rem;
  margin-top: -22px;
}

#impo li {
  list-style: none;
}

/* footer =========== */

footer {
  background: #333;
  color: #888;
}

footer h3 {
  color: #fff;
  padding-bottom: 5px;
  margin-bottom: 30px;
  border-bottom: 1px solid #888;
}

footer .footer-icon {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px !important;
  color: #fff;
}

footer .footer-icon:nth-of-type(1) {
  background: #3b5998;
}

footer .footer-icon:nth-of-type(2) {
  background: #55acee;
}

footer .footer-icon:nth-of-type(3) {
  background: #dd4b39;
}

footer .footer-icon:nth-of-type(4) {
  background: #ff0000;
}

footer .footer-icon:nth-of-type(5) {
  background: #007bb5;
}

footer ul li {
  padding: 10px 0;
  color: #fff;
  border-bottom: 1px solid #888;
}

footer button {
  padding: 5px 10px;
  background: #696969;
  border: 0;
  color: #f7f7f7;
  margin-bottom: 5px;
}

footer img {
  width: 70px;
  height: 70px;
  border: 1px solid #888;
  padding: 1px;
}

.copyright {
  background: #232323;
  color: #fff;
}

.copyright .copy-desc, .copyright ul {
  margin: 0;
}

.copyright .copy-desc i {
  color: #7cb62f;
}

.copyright ul {
  display: flex;
  justify-content: space-between;
}

.copyright ul li {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.copyright ul li:hover {
  color: #7cb62f;
}