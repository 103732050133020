@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Amiri&family=Open+Sans&family=Poiret+One&family=Reem+Kufi&family=Roboto&display=swap");
:root {
  --primary-color: #3a4052;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background: rgb(23, 33, 80) !important;
  direction: rtl;
  line-height: 1.5;
  font-family: "Amiri", serif !important;
}

.card-speakers {
  padding: 0.8rem;
  line-height: 2rem;
  margin: 0.7rem 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.25s;
  margin: 0.5rem 0 1rem;
  border-radius: 8px;
  background-color: rgb(13, 110, 148);
}

.p-1 {
  padding: 30px;
}