@import url(https://fonts.googleapis.com/css2?family=Cairo:wght@200;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Amiri&family=Open+Sans&family=Poiret+One&family=Reem+Kufi&family=Roboto&display=swap);
:root {
  --primary-color: #3a4052;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background: rgb(23, 33, 80) !important;
  direction: rtl;
  line-height: 1.5;
  font-family: "Amiri", serif !important;
}

.card-speakers {
  padding: 0.8rem;
  line-height: 2rem;
  margin: 0.7rem 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.25s;
  margin: 0.5rem 0 1rem;
  border-radius: 8px;
  background-color: rgb(13, 110, 148);
}

.p-1 {
  padding: 30px;
}
.goals h6 {
    font-size: 1.6rem;
}
.modal-open {
  overflow: hidden;
}

.btn-outline {
  text-align: center;
  border: dotted 1px #274850;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: 80%;
  margin: auto;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header, .modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer>* {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.dropdown {
  display: inline;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  color: var(--primary-color);
  text-align: right;
  font-size: 1.1rem;
  white-space: nowrap;
  background-color: rgb(238, 236, 236);
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: var(--primary-color);
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-menu.show {
  display: block;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 70vw;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
  .post {
    grid-template-columns: 1fr;
  }
  .post a, .post button {
    padding: 0.3rem 0.4rem;
  }
}

@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
header {
  height: auto;
  padding: 0;
  position: fixed;
  width: 100%;
  z-index: 999;
}

header i.icon {
  position: absolute;
  right: 30px;
  top: 32px;
  cursor: pointer;
  z-index: 10;
  display: none;
}

.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}

header .logo h2 {
  font-size: 30px;
  color: #fff;
}

header nav .nav-list {
  list-style: none;
  margin-bottom: 0;
  display: flex;
  justify-content: space-around;
}

header .navbar-light .navbar-nav .nav-link {
  display: block;
  padding: 5px 0.3rem;
  margin: 9px 0.2rem;
  font-size: 1.2rem;
  color: #fff;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.nav-link a {
  color: #7cb62f;
}

a.nav-line {
  text-decoration: none;
  border: 3px solid rgb(23, 33, 80);
  color: rgb(23, 33, 80);
  border-radius: 10px;
  padding: 0.2rem 1rem;
  margin: 0 0.7rem;
}

a.nav-line:hover {
  color: #fff;
  background: rgb(23, 33, 80);
  transition: all 0.3s ease-in-out;
}

header .navbar-light .navbar-nav .lang {
  padding: 0;
  margin: 0 !important;
}

.image-details {
  max-width: 90%;
}

header .navbar-light .navbar-nav .nav-link:hover, header .navbar-light .navbar-nav a:hover {
  color: #7cb62f;
}

.sticky {
  background: rgba(23, 33, 80, 0.7);
  box-shadow: 0 2px 10px 0 #000;
  color: #fff !important;
  transition: all 0.3s ease-in-out;
}

.sticky .navbar-light .navbar-nav .nav-link {
  padding: 14px 0.3rem;
  transition: all 0.4s ease-in-out;
}

.sticky .logo h2 {
  color: #000;
}

@media (max-width: 575px) {
  .navbar-light .navbar-toggler {
    color: #ccc !important;
    border-color: rgba(255, 255, 255, 0.8);
  }
  header {
    background: #00000065;
  }
  header .logo {
    text-align: center;
  }
  header nav .nav-list {
    padding: 0;
    text-align: center;
    display: none;
  }
  header i.icon {
    display: block;
  }
}

/* home */

a {
  text-decoration: none;
  color: var(--primary-color);
}

.showcase {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  color: #fff;
}

.video-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: var(--primary-color) url("/img/1.jpg") no-repeat center center/cover;
}

.content {
  z-index: 10;
}

.btn-our {
  background-color: #7cb62f;
  border-radius: 30px;
  padding: 0.5rem 1rem;
  color: white;
}

.btn-our:hover {
  padding: 0.6rem 1.6rem;
  background: #72c70a;
  border: 1px solid #fff;
  transition: all 0.5s ease-in-out;
  color: white;
}

.btn-our:hover {}

.video-container video {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-container:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(4, 5, 12, 0.6);
}

/* team ============= */

.item .team-info {
  text-align: right;
  color: #fff;
  direction: rtl;
}

.item .team-info img {
  max-height: 280px;
  max-width: 280px;
}

.item .team-info .item-info-title {
  margin: auto;
  margin: 5px 0;
  padding-right: 0.7rem;
}

.item .team-info .item-info-desc {
  display: block;
  margin-bottom: 5px;
  padding-right: 1rem;
}

.item .team-info .en {
  text-align: left;
}

.item .team-info .team-info-social {
  background: #252525 !important;
  position: relative;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  color: #fff;
}

.item .team-info .team-info-social a {
  position: relative;
  margin: auto;
  z-index: 15;
  color: #72c70a;
}

.item .team-info .bg {
  display: block;
  position: absolute;
  width: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: all 0.5s ease-in-out;
}

.item .team-info:hover .bg {
  width: 100%;
  background: #7cb62f;
}

.item .team-info:hover .team-info-social a {
  color: #252525;
  font-size: 1rem;
  cursor: pointer;
}

.owl-nav {
  padding: 0;
  text-align: center;
  font-size: 4rem;
  color: #fff;
}

.owl-dots .owl-dot {
  width: 35px;
  height: 9px;
  background: rgb(23, 33, 80) !important;
  margin: 0 0.2rem;
  border-radius: 15px;
  border: #7cb62f solid 1px !important;
}

.owl-dots .owl-dot:hover {
  background: #7cb62f !important;
  transition: all 0.3s ease-in-out;
}

.owl-dots .active {
  background: #7cb62f !important;
  border: none;
}

.goals {
  -webkit-clip-path: polygon(0 0, 100% 1%, 100% 94%, 0 100%);
          clip-path: polygon(0 0, 100% 1%, 100% 94%, 0 100%);
  background-image: url(/static/media/lap-stat.ac2ffc88.jpg);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: relative;
  color: #fff;
}

.overlay {
  background: rgba(0, 0, 0, 0.8);
}

.nav-tabs .nav-link.active {
  color: #fff;
  background-color: #7cb62f;
  border-color: #dee2e6;
  border-bottom: none;
}

.nav-tabs a {
  text-decoration: none;
  color: #7cb62f;
}

/* Services Section ====================== */

.services .serv {
  background: #fff;
  padding: 10px;
  text-align: center;
  margin-bottom: 20px;
  transition: all 0.3s ease-in-out;
  border: 2px solid #7cb62f;
  border-radius: 80px;
}

.services .serv .icons {
  background: #7cb62f;
  margin: 0.5rem auto;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  color: #fff;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  border: 2px solid #f6f6f6;
}

.services .serv-title {
  font-weight: bold;
  font-size: 25px;
  color: #777;
  margin-bottom: 20px;
  transition: all 0.3s ease-in-out;
}

.non:hover {
  text-decoration: none;
}

.services .serv-desc {
  font-size: 17px;
  color: #888;
  margin-bottom: 20px;
  height: 200px;
  padding: 0.3rem auto;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.services .serv:hover {
  background: #7cb62f;
}

.services .serv:hover h2.icons {
  background: #fff;
  color: #7cb62f;
}

.services .serv:hover .serv-title, .services .serv:hover .serv-desc {
  color: #fff;
}

/* testimonial ============= */

.testimonial {
  -webkit-clip-path: polygon(0 0, 100% 1%, 100% 94%, 0 100%);
          clip-path: polygon(0 0, 100% 1%, 100% 94%, 0 100%);
  background-color: #232323;
  padding: 3rem 1.2rem;
}

.testimonial .section-header {
  color: #fff;
}

.testimonial .test-info {
  text-align: center;
  background-color: #141414;
  padding: 20px 10px;
  color: #fff;
}

.testimonial .test-info img {
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid #000;
  margin: auto;
}

.testimonial .test-info .test-info-title {
  font-size: 20px;
  margin-bottom: 10px;
}

.testimonial .test-info .test-info-span {
  color: #999;
  display: block;
  margin-bottom: 10px;
}

.testimonial .test-info i {
  color: #f2b100;
  margin-bottom: 10px;
}

#members .owl-nav {
  display: block;
}

#speakers .owl-dots {
  display: block;
}

.owl-next, .owl-prev {
  margin: 0 2rem;
  color: #fff;
}

.testimonial .test-info .test-info-desc {
  color: #999;
}

/* Important Date===== */

#impo {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 93%);
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 93%);
  background: rgb(13, 110, 148);
  padding: 5rem 1.2rem;
  margin-top: -22px;
}

#impo li {
  list-style: none;
}

/* footer =========== */

footer {
  background: #333;
  color: #888;
}

footer h3 {
  color: #fff;
  padding-bottom: 5px;
  margin-bottom: 30px;
  border-bottom: 1px solid #888;
}

footer .footer-icon {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px !important;
  color: #fff;
}

footer .footer-icon:nth-of-type(1) {
  background: #3b5998;
}

footer .footer-icon:nth-of-type(2) {
  background: #55acee;
}

footer .footer-icon:nth-of-type(3) {
  background: #dd4b39;
}

footer .footer-icon:nth-of-type(4) {
  background: #ff0000;
}

footer .footer-icon:nth-of-type(5) {
  background: #007bb5;
}

footer ul li {
  padding: 10px 0;
  color: #fff;
  border-bottom: 1px solid #888;
}

footer button {
  padding: 5px 10px;
  background: #696969;
  border: 0;
  color: #f7f7f7;
  margin-bottom: 5px;
}

footer img {
  width: 70px;
  height: 70px;
  border: 1px solid #888;
  padding: 1px;
}

.copyright {
  background: #232323;
  color: #fff;
}

.copyright .copy-desc, .copyright ul {
  margin: 0;
}

.copyright .copy-desc i {
  color: #7cb62f;
}

.copyright ul {
  display: flex;
  justify-content: space-between;
}

.copyright ul li {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.copyright ul li:hover {
  color: #7cb62f;
}
.timer {
  padding: 10px 20px;
  margin-top: 4rem;
  font-size: 1.4rem;
  background-color: rgba(62, 79, 155, 0.7);
}

@media screen and (max-width: 800px) {
  .timer {
    padding: 5px 8px;
  }
}

select{background:none;background-color:white;color:grey;font-size:18px;display:block;width:100%;border:none;border-radius:0;border-bottom:1px solid grey}.group{position:relative;margin:45px 0}.group .form-input{background:none;background-color:white;color:grey;font-size:18px;padding:10px 10px 10px 5px;display:block;width:100%;border:none;border-radius:0;border-bottom:1px solid grey;margin:15px 0}.group .form-input:focus{outline:none;border-bottom:1px solid #5587b6}.group .form-input:focus ~ .form-input-label{top:-14px;font-size:12px;color:#5587b6}.group input[type="password"]{letter-spacing:0.3em}.group .form-input-label{color:grey;font-size:16px;font-weight:normal;position:absolute;pointer-events:none;right:5px;top:10px;transition:300ms ease all}.group .form-input-label.shrink{top:-14px;font-size:12px;color:#5587b6}

.video-content>div:nth-child(1){width:100% !important}

